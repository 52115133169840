import React, { useState } from "react";
import { useAuth } from "../context/AuthProvider";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Dialog, DialogPanel } from "@headlessui/react";
import { Link, useNavigate } from "react-router-dom";

const NavBar = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { auth, user, signOut } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async (e) => {
    e.preventDefault();
    setMobileMenuOpen(false);
    try {
      const { error } = await signOut();
      if (!error) {
        navigate("/");
      }
      console.log(error);
    } catch (error) {
      console.log(error);
    }
  };

  const navigation = [
    { name: "Home", href: "/" },
    { name: "Map", href: "/map" },
    { name: "Advocacy", href: "/coalition" },
    { name: "Add Campsite", href: "/addNew" },
  ];

  return (
    <header className="bg-gray-900">
      <nav
        aria-label="Global"
        className="mx-auto flex items-center justify-between p-6 lg:px-8"
      >
        <div className="flex items-center gap-x-12">
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <Link
                key={item.name}
                to={item.href}
                className="text-sm font-semibold leading-6 text-white"
              >
                {item.name}
              </Link>
            ))}
          </div>
        </div>
        <div className="w-full flex lg:hidden justify-between">
          <Link to={"/"} className="font-bold leading-6 text-white">
            GoBikeCamping
          </Link>
          <button
            type="button"
            onClick={() => setMobileMenuOpen(true)}
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white"
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon aria-hidden="true" className="h-6 w-6" />
          </button>
        </div>
        <div className="hidden lg:flex">
          {!auth ? (
            <div className="hidden lg:flex lg:flex-1 lg:justify-end">
              <Link
                to="/login"
                className="text-sm font-semibold leading-6 text-white"
              >
                Log in <span aria-hidden="true">&rarr;</span>
              </Link>
            </div>
          ) : (
            <div className="hidden lg:flex lg:flex-1 lg:justify-end">
              <div className="hidden lg:flex lg:gap-x-12">
                {user.user_metadata.first_name ? (
                  <Link
                    to="/profile"
                    className="text-sm font-semibold leading-6 text-white hover:text-gray-300"
                  >
                    Hello &#x1F44B; {user.user_metadata.first_name}
                  </Link>
                ) : (
                  ""
                )}
                <Link
                  as="Button"
                  className="text-sm font-semibold leading-6 text-white"
                  onClick={handleLogout}
                >
                  Log out
                </Link>
              </div>
            </div>
          )}
        </div>
      </nav>
      <Dialog
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
        className="lg:hidden"
      >
        <div className="fixed inset-0 z-10" />
        <DialogPanel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-end">
            <button
              type="button"
              onClick={() => setMobileMenuOpen(false)}
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    onClick={() => setMobileMenuOpen(false)}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
              <div className="py-6">
                {!auth ? (
                  <div className="-mx-3 block rounded-lg px-3 py-2.5 hover:bg-gray-50">
                    <Link
                      to="/login"
                      className="text-base font-semibold leading-7 text-gray-900"
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      Log in <span aria-hidden="true">&rarr;</span>
                    </Link>
                  </div>
                ) : (
                  <>
                    <div className="-mx-3 block rounded-lg px-3 py-2.5 hover:bg-gray-50">
                      <Link
                        to="/profile"
                        className="text-base font-semibold leading-7 text-gray-900"
                        onClick={() => setMobileMenuOpen(false)}
                      >
                        Profile
                      </Link>
                    </div>
                    <div className="-mx-3 block rounded-lg px-3 py-2.5 text-gray-900 hover:bg-gray-50">
                      <Link
                        as="Button"
                        className="text-base font-semibold leading-7 text-gray-900"
                        onClick={handleLogout}
                      >
                        Log out
                      </Link>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>
  );
};

export default NavBar;

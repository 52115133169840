import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

export default function BikeShopInfoDrawer({
  bikeShopInfoOpen,
  setBikeShopInfoOpen,
  properties,
  coordinates,
}) {
  function websiteFormatter(val) {
    if (val) {
      return (
        <a
          className="cursor-pointer underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
          target="_blank"
          href={val}
          rel="noopener noreferrer"
        >
          Visit Website
        </a>
      );
    } else {
      return "n/a";
    }
  }

  function phoneFormatter(val) {
    if (val) {
      return (
        <a
          className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
          href={"tel:" + val}
        >
          {val.slice(0, 3) + "-" + val.slice(3, 6) + "-" + val.slice(6)}
        </a>
      );
    } else {
      return "unknown";
    }
  }

  return (
    <Transition.Root show={bikeShopInfoOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => setBikeShopInfoOpen(false)}
      >
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="px-4 py-6 sm:px-6">
                      <div className="flex items-start justify-between">
                        <h2
                          id="slide-over-heading"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          {properties.name}
                        </h2>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
                            onClick={() => setBikeShopInfoOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* Main */}
                    <div>
                      <div className="px-4 pt-5 pb-5 sm:px-0 sm:pt-0">
                        <dl className="space-y-8 px-4 sm:space-y-6 sm:px-6">
                          <div>
                            {/* <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">
                              Services
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                              <ul className="list-disc pl-4">
                                {properties.can_weld && (
                                  <li>Frame welding available</li>
                                )}
                                {properties.has_bike_boxes && (
                                  <li>Bike boxes for shipping/transport</li>
                                )}
                                {properties.has_touring_tires && (
                                  <li>Touring tires in stock</li>
                                )}
                                {properties.has_touring_gear && (
                                  <li>Touring gear and equipment</li>
                                )}
                                {properties.services?.map((service, index) => (
                                  <li key={index}>{service}</li>
                                ))}
                              </ul>
                            </dd> */}
                          </div>
                          <div>
                            <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">
                              Contact
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                              <p>
                                Phone: {phoneFormatter(properties.phone_number)}
                              </p>
                              <p>
                                Website:{" "}
                                {websiteFormatter(properties.website_url)}
                              </p>
                              {properties.email && (
                                <p>Email: {properties.email}</p>
                              )}
                            </dd>
                          </div>
                          <div>
                            <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">
                              Location
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                              <a
                                className="cursor-pointer underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                                target="_blank"
                                rel="noopener noreferrer"
                                href={
                                  "https://www.google.com/maps/place/" +
                                  coordinates[1] +
                                  "," +
                                  coordinates[0]
                                }
                              >
                                View on Google Maps
                              </a>
                            </dd>
                          </div>
                        </dl>
                      </div>
                    </div>

                    <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                      <div className="flex justify-between">
                        <Link
                          to={`/bikeshop/${properties.id}`}
                          className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
                        >
                          View full details
                        </Link>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

import { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { supabase } from "../supabase/client";
import { useAuth } from "../context/AuthProvider";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";

export function BikeShopView() {
  const { id } = useParams();
  const { user } = useAuth();
  const [bikeShopData, setBikeShopData] = useState({
    latitude: 0,
    longitude: 0,
    checkins: [],
  });
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const navigate = useNavigate();

  // Validate UUID format
  const isValidUUID = (uuid) => {
    const uuidRegex =
      /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return uuidRegex.test(uuid);
  };

  async function fetchBikeShopData() {
    if (!isValidUUID(id)) {
      setNotFound(true);
      setLoading(false);
      return;
    }

    const { data, error } = await supabase
      .from("bike_shops")
      .select(
        `*,
            checkins (
                *,
                profiles (
                    username
                )
            )
        `
      )
      .eq("id", id)
      .single();

    if (error) {
      console.error("Error fetching bike shop:", error);
      setNotFound(true);
    } else if (data) {
      // Sort checkins if they exist
      if (data.checkins) {
        data.checkins.sort(
          (a, b) => new Date(b.check_in_date) - new Date(a.check_in_date)
        );
      } else {
        data.checkins = []; // Ensure checkins is always an array
      }
      setBikeShopData(data);
    } else {
      setNotFound(true);
    }
    setLoading(false);
  }

  function websiteFormatter(val) {
    if (val) {
      return (
        <a
          className="cursor-pointer underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
          target="_blank"
          href={val}
          rel="noopener noreferrer"
        >
          URL
        </a>
      );
    } else {
      return "n/a";
    }
  }

  function phoneFormatter(val) {
    if (val) {
      return (
        <a
          className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
          href={"tel:" + val}
        >
          {val.slice(0, 3) + "-" + val.slice(3, 6) + "-" + val.slice(6)}
        </a>
      );
    } else {
      return "unknown";
    }
  }

  function booleanFormatter(val) {
    if (val === true) {
      return "Yes";
    } else if (val === false) {
      return "No";
    } else {
      return "Unsure";
    }
  }

  const goToCheckinPage = () => {
    navigate("/checkin", { state: { properties: bikeShopData } });
  };

  useEffect(() => {
    fetchBikeShopData();
  }, [id]);

  const LoadingPlaceholder = () => (
    <div className="animate-pulse">
      <div className="h-4 bg-gray-200 rounded w-1/4 mb-2"></div>
      <div className="h-4 bg-gray-200 rounded w-1/2"></div>
    </div>
  );

  if (notFound) {
    return (
      <div className="bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          <p className="text-base font-semibold text-blue-600">404</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Bike shop not found
          </h1>
          <p className="mt-6 text-base leading-7 text-gray-600">
            Sorry, we couldn't find the bike shop you're looking for.
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Link
              to="/"
              className="rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            >
              Go back home
            </Link>
            <Link to="/map" className="text-sm font-semibold text-gray-900">
              View map <span aria-hidden="true">&rarr;</span>
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="bg-white px-6 py-14 lg:px-8 w-full max-w-7xl mx-auto">
        <div className="mx-auto text-base leading-7 text-gray-700">
          <div className="mb-4">
            {loading ? (
              <div className="animate-pulse">
                <div className="h-8 bg-gray-200 rounded w-1/3 mb-2"></div>
                <div className="h-4 bg-gray-200 rounded w-1/4"></div>
              </div>
            ) : (
              <>
                <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  {bikeShopData.name}
                </h1>
                <p>
                  {bikeShopData.city}, {bikeShopData.state}
                </p>
              </>
            )}
          </div>
          {/* 3 column wrapper */}
          <div className="mx-auto w-full max-w-7xl grow">
            {/* Left sidebar & main wrapper */}
            <div className="grid md:grid-cols-4 grid-cols-1">
              <div className="pr-4 col-span-1">
                {/* Left column area */}
                <div>
                  <h3 className="mt-2 text-2xl font-medium tracking-tight text-gray-900 mb-2">
                    Details
                  </h3>
                  {loading ? (
                    <div className="space-y-4">
                      <LoadingPlaceholder />
                      <LoadingPlaceholder />
                      <LoadingPlaceholder />
                      <LoadingPlaceholder />
                      <LoadingPlaceholder />
                    </div>
                  ) : (
                    <>
                      <p>
                        <span className="font-bold">Address:</span>{" "}
                        <a
                          className="cursor-pointer underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                          target="_blank"
                          href={encodeURI(
                            "https://www.google.com/maps/search/?api=1&query=" +
                              bikeShopData.address +
                              " " +
                              bikeShopData.city +
                              " " +
                              bikeShopData.state +
                              " " +
                              bikeShopData.zip_code
                          )}
                          rel="noopener noreferrer"
                        >
                          {bikeShopData.address}
                        </a>
                      </p>
                      <p>
                        <span className="font-bold">GPS:</span>{" "}
                        <a
                          className="cursor-pointer underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                          target="_blank"
                          href={
                            "https://www.google.com/maps/place/" +
                            bikeShopData.latitude +
                            "," +
                            bikeShopData.longitude
                          }
                          rel="noopener noreferrer"
                        >
                          {bikeShopData.latitude.toString().slice(0, 7) +
                            "," +
                            bikeShopData.longitude.toString().slice(0, 8)}
                        </a>
                      </p>
                      <p>
                        <span className="font-bold">Website:</span>{" "}
                        {websiteFormatter(bikeShopData.website_url)}
                      </p>
                      <p>
                        <span className="font-bold">Phone:</span>{" "}
                        {phoneFormatter(bikeShopData.phone_number)}
                      </p>
                    </>
                  )}
                </div>
                <div>
                  <h3 className="mt-2 text-2xl font-medium tracking-tight text-gray-900 mb-2">
                    Services
                  </h3>
                  {loading ? (
                    <div className="space-y-4">
                      <LoadingPlaceholder />
                      <LoadingPlaceholder />
                      <LoadingPlaceholder />
                      <LoadingPlaceholder />
                    </div>
                  ) : (
                    <>
                      <p>
                        <span className="font-bold">Frame Welding:</span>{" "}
                        {booleanFormatter(bikeShopData.can_weld)}
                      </p>
                      <p>
                        <span className="font-bold">Bike Boxes:</span>{" "}
                        {booleanFormatter(bikeShopData.has_bike_boxes)}
                      </p>
                      <p>
                        <span className="font-bold">
                          Carries Touring Tires:
                        </span>{" "}
                        {booleanFormatter(bikeShopData.has_touring_tires)}
                      </p>
                      <p>
                        <span className="font-bold">Carries Touring Gear:</span>{" "}
                        {booleanFormatter(bikeShopData.has_touring_gear)}
                      </p>
                      <p>
                        <span className="font-bold">
                          Certified Bike Fitter:
                        </span>{" "}
                        {booleanFormatter(bikeShopData.has_bike_fitter)}
                      </p>
                    </>
                  )}
                </div>
                <h3 className="mt-2 text-2xl font-medium tracking-tight text-gray-900 mb-2">
                  Something wrong?
                </h3>
                <a
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  href={
                    "mailto:gobikecamping@gmail.com?subject=Report for Bike Shop: " +
                    bikeShopData.id
                  }
                >
                  Report it
                </a>
              </div>

              <div className="md:px-4 mt-5 md:mt-0 col-span-2 md:max-w-xl">
                <div>
                  {loading ? (
                    <div className="space-y-4">
                      <LoadingPlaceholder />
                      <LoadingPlaceholder />
                      <LoadingPlaceholder />
                      <LoadingPlaceholder />
                    </div>
                  ) : (
                    <>
                      <h3 className="mt-2 text-2xl font-medium tracking-tight text-gray-900 mb-2">
                        Description
                      </h3>
                      <p>{bikeShopData.description}</p>
                    </>
                  )}
                </div>
              </div>

              <div className="md:pl-4 mt-5 md:mt-0 col-span-1">
                <h3 className="mt-2 text-2xl font-medium tracking-tight text-gray-900 mb-2">
                  Check-ins
                </h3>
                {loading ? (
                  <div className="space-y-4">
                    {[...Array(3)].map((_, i) => (
                      <div key={i} className="border-b pb-4">
                        <div className="flex justify-between items-center mb-2">
                          <div className="w-1/3">
                            <LoadingPlaceholder />
                          </div>
                          <div className="w-24">
                            <LoadingPlaceholder />
                          </div>
                        </div>
                        <div className="w-full">
                          <LoadingPlaceholder />
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <>
                    <ul role="list" className="grid grid-cols-1 gap-6">
                      {bikeShopData.checkins.map((checkin) => (
                        <li
                          key={checkin.id}
                          className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow"
                        >
                          <div className="flex w-full items-center justify-between space-x-6 p-6">
                            <div className="flex-1">
                              <div className="flex items-center space-x-3">
                                <h3 className="text-sm font-medium text-gray-900">
                                  {checkin.check_in_date}
                                </h3>
                                <span className="inline-flex flex-shrink-0 items-center rounded-full px-0.5 py-0.5 text-xs font-medium">
                                  by: {checkin.profiles.username}
                                </span>
                              </div>
                              <p className="mt-1 text-sm text-gray-500">
                                {checkin.description}
                              </p>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                    {bikeShopData.checkins.length > 0 ? (
                      <button
                        onClick={() => goToCheckinPage()}
                        className="mt-5 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                      >
                        Check-in here
                      </button>
                    ) : (
                      <div>
                        <p>No one has checked-in yet.</p>
                        <button
                          onClick={() => goToCheckinPage()}
                          className="mt-5 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                        >
                          Be the first
                        </button>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

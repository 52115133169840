import React from "react";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import "../../styles/FilterControlStyles.css";
import { Link } from "react-router-dom";

export default function BikeShopPopup({
  properties,
  coordinates,
  setBikeShopInfoOpen,
}) {
  return (
    <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
      <div
        className="font-bold text-base px-2 py-3 sm:p-3"
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {properties.name}
      </div>
      <div className="p-3 display-linebreak">
        {properties.description ? (
          <p style={{ marginTop: "10px" }}>
            {properties.description.substring(0, 100) + "..."}
          </p>
        ) : (
          ""
        )}
        <a
          className="cursor-pointer underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
          target="_blank"
          href={
            "https://www.google.com/maps/place/" +
            coordinates[1] +
            "," +
            coordinates[0]
          }
          rel="noopener noreferrer"
        >
          Map
        </a>

        {properties.phone_number ? (
          <span>
            <br />
            <a
              className="cursor-pointer underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
              href={"tel:" + properties.phone_number}
            >
              {properties.phone_number.slice(0, 3) +
                "-" +
                properties.phone_number.slice(3, 6) +
                "-" +
                properties.phone_number.slice(6)}
            </a>
          </span>
        ) : (
          ""
        )}

        {properties.website_url ? (
          <span>
            <br />
            <a
              className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
              target="_blank"
              href={properties.website_url}
              rel="noopener noreferrer"
            >
              Website
            </a>
          </span>
        ) : (
          ""
        )}
      </div>
      <div
        className="flex items-center justify-center bg-white p-1"
        style={{ borderTop: "none" }}
      >
        <div className="flex flex-wrap justify-center gap-x-6 gap-y-4">
          <a
            className="cursor-pointer underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
            onClick={() => setBikeShopInfoOpen(true)}
          >
            more info &gt;&gt;
          </a>
          <Link
            key={properties.id}
            target={"_blank"}
            to={`/bikeshop/${properties.id}`}
            className="cursor-pointer underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
          >
            bike shop page &gt;&gt;
          </Link>
        </div>
      </div>
    </div>
  );
}

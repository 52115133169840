import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { supabase } from "../supabase/client";
import { PageNotFoundView } from "./PageNotFoundView";

const LoadingPlaceholder = () => (
  <>
    <div className="mb-8">
      <div className="animate-pulse">
        <div className="h-8 bg-gray-200 rounded w-1/3 mb-2"></div>
        <div className="h-4 bg-gray-200 rounded w-1/6 mb-4"></div>
        <div className="h-4 bg-gray-200 rounded w-2/3"></div>
      </div>
    </div>

    <div className="overflow-x-auto -mx-4 sm:mx-0">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="px-4 sm:px-12 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Campsite Name
            </th>
            <th
              scope="col"
              className="px-4 sm:px-12 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              State
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {[...Array(5)].map((_, index) => (
            <tr key={index}>
              <td className="px-4 sm:px-12 py-4 whitespace-normal">
                <div className="animate-pulse">
                  <div className="h-4 bg-gray-200 rounded w-48"></div>
                </div>
              </td>
              <td className="px-4 sm:px-12 py-4 whitespace-normal">
                <div className="animate-pulse">
                  <div className="h-4 bg-gray-200 rounded w-16"></div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </>
);

const PublicCollectionView = () => {
  const { id } = useParams();
  const [collection, setCollection] = useState(null);
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    fetchCollection();
  }, [id]);

  const fetchCollection = async () => {
    try {
      const { data, error } = await supabase
        .from("collections")
        .select(
          `
          *,
          collection_items (
            campsite_id,
            campsites (
              id,
              name,
              state
            )
          )
        `
        )
        .eq("id", id)
        .single();

      if (error || !data || !data.is_public) {
        setNotFound(true);
        setLoading(false);
        return;
      }

      setCollection(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching collection:", error);
      setNotFound(true);
      setLoading(false);
    }
  };

  if (notFound) return <PageNotFoundView />;

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-grow max-w-screen-2xl mx-auto p-4 sm:p-8">
        {loading ? (
          <LoadingPlaceholder />
        ) : (
          <>
            <div className="mb-8">
              <h1 className="text-2xl sm:text-3xl font-bold mb-2">
                {collection.name}
              </h1>
              <p className="text-gray-500 mb-4">
                {collection.collection_items.length} campsites
              </p>
              {collection.description && (
                <p className="text-gray-600">{collection.description}</p>
              )}
            </div>

            <div className="overflow-x-auto -mx-4 sm:mx-0">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-4 sm:px-12 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Campsite Name
                    </th>
                    <th
                      scope="col"
                      className="px-4 sm:px-12 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      State
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {collection.collection_items.map((item) => (
                    <tr key={item.campsite_id} className="hover:bg-gray-50">
                      <td className="px-4 sm:px-12 py-4 whitespace-normal">
                        <Link
                          to={`/campsite/${item.campsites.id}`}
                          className="text-blue-600 hover:text-blue-800"
                        >
                          {item.campsites.name}
                        </Link>
                      </td>
                      <td className="px-4 sm:px-12 py-4 whitespace-normal text-gray-500">
                        {item.campsites.state}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PublicCollectionView;

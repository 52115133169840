import { useState, useEffect, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { PlusIcon } from "@heroicons/react/24/outline";
import { supabase } from "../supabase/client";

export default function CollectionsModal({
  isOpen,
  onClose,
  campsiteId,
  userId,
}) {
  const [collections, setCollections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showNewCollectionInput, setShowNewCollectionInput] = useState(false);
  const [newCollectionName, setNewCollectionName] = useState("");
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  useEffect(() => {
    if (isOpen) {
      fetchCollections();
    }
  }, [isOpen, userId]);

  const fetchCollections = async () => {
    try {
      const { data, error } = await supabase
        .from("collections")
        .select(
          `
          *,
          collection_items!inner (
            campsite_id
          )
        `
        )
        .eq("user_id", userId);

      if (error) throw error;
      setCollections(data || []);
    } catch (error) {
      console.error("Error fetching collections:", error);
      setError("Failed to load collections");
    } finally {
      setLoading(false);
    }
  };

  const createCollection = async () => {
    if (!newCollectionName.trim()) {
      setError("Please enter a collection name");
      return;
    }

    try {
      // Create new collection
      const { data: collectionData, error: collectionError } = await supabase
        .from("collections")
        .insert([
          {
            name: newCollectionName.trim(),
            user_id: userId,
          },
        ])
        .select()
        .single();

      if (collectionError) throw collectionError;

      // Add campsite to the new collection
      const { error: linkError } = await supabase
        .from("collection_items")
        .insert([
          {
            collection_id: collectionData.id,
            campsite_id: campsiteId,
          },
        ]);

      if (linkError) throw linkError;

      setSuccessMessage("Added to new collection!");
      setNewCollectionName("");
      setShowNewCollectionInput(false);
      await fetchCollections();
    } catch (error) {
      console.error("Error creating collection:", error);
      setError("Failed to create collection");
    }
  };

  const addToCollection = async (collectionId) => {
    try {
      const { error } = await supabase.from("collection_items").insert([
        {
          collection_id: collectionId,
          campsite_id: campsiteId,
        },
      ]);

      if (error) throw error;

      setSuccessMessage("Added to collection!");
      await fetchCollections();
    } catch (error) {
      console.error("Error adding to collection:", error);
      setError("Failed to add to collection");
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900 mb-4"
                >
                  Add to Collection
                </Dialog.Title>

                {error && (
                  <div className="mb-4 text-sm text-red-600">{error}</div>
                )}
                {successMessage && (
                  <div className="mb-4 text-sm text-green-600">
                    {successMessage}
                  </div>
                )}

                {loading ? (
                  <div className="py-4">Loading collections...</div>
                ) : (
                  <>
                    {/* Existing Collections */}
                    <div className="space-y-2 mb-4">
                      {collections.map((collection) => (
                        <button
                          key={collection.id}
                          onClick={() => addToCollection(collection.id)}
                          className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 rounded-md"
                        >
                          {collection.name}
                          <span className="text-gray-400 text-xs ml-2">
                            ({collection.collection_items.length} campsites)
                          </span>
                        </button>
                      ))}
                    </div>

                    {/* Create New Collection */}
                    {showNewCollectionInput ? (
                      <div className="mt-4">
                        <input
                          type="text"
                          value={newCollectionName}
                          onChange={(e) => setNewCollectionName(e.target.value)}
                          placeholder="Collection name"
                          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                        />
                        <div className="mt-2 flex gap-2">
                          <button
                            onClick={createCollection}
                            className="inline-flex justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                          >
                            Create & Add
                          </button>
                          <button
                            onClick={() => setShowNewCollectionInput(false)}
                            className="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    ) : (
                      <button
                        onClick={() => setShowNewCollectionInput(true)}
                        className="inline-flex items-center gap-2 text-sm text-blue-600 hover:text-blue-800"
                      >
                        <PlusIcon className="h-4 w-4" />
                        Create new collection
                      </button>
                    )}
                  </>
                )}

                <div className="mt-4">
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={onClose}
                  >
                    Close
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

import React, { useEffect, useState } from "react";
import { useAuth } from "../context/AuthProvider";
import { supabase } from "../supabase/client";
import { Link } from "react-router-dom";

const LoadingPlaceholder = () => (
  <div className="animate-pulse">
    <div className="h-4 bg-gray-200 rounded w-1/4 mb-2"></div>
    <div className="h-4 bg-gray-200 rounded w-1/2"></div>
  </div>
);

const ProfileView = () => {
  const { user } = useAuth();
  const [campsites, setCampsites] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const [collections, setCollections] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [username, setUsername] = useState("");
  const [signUpOrder, setSignUpOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [campsitesExpanded, setCampsitesExpanded] = useState(false);
  const [favoritesExpanded, setFavoritesExpanded] = useState(false);
  const [collectionsExpanded, setCollectionsExpanded] = useState(false);
  const [reviewsExpanded, setReviewsExpanded] = useState(false);

  const ITEMS_TO_SHOW = 5;

  useEffect(() => {
    const initializeProfile = async () => {
      if (user) {
        await Promise.all([
          fetchUserCampsites(),
          fetchUsername(),
          fetchUserReviews(),
          fetchUserFavorites(),
          fetchUserCollections(),
        ]);
      }
      setLoading(false);
    };

    initializeProfile();
  }, [user]);

  const fetchUsername = async () => {
    try {
      const { data, error } = await supabase
        .from("profiles")
        .select("username, sign_up_order")
        .eq("id", user.id)
        .single();

      if (error) {
        console.error("Error fetching username:", error);
        setError("Error fetching username: " + error.message);
        return;
      }

      if (data) {
        setUsername(data.username);
        setSignUpOrder(data.sign_up_order);
      }
    } catch (error) {
      console.error("Error fetching username:", error);
      setError("Unexpected error: " + error.message);
    }
  };

  const fetchUserCollections = async () => {
    try {
      const { data, error } = await supabase
        .from("collections")
        .select(
          `
          *,
          collection_items (
            campsite_id
          )
        `
        )
        .eq("user_id", user.id);

      if (error) {
        console.error("Error fetching collections:", error);
        setError("Error fetching your collections: " + error.message);
        return;
      }

      setCollections(data || []);
    } catch (error) {
      console.error("Error fetching collections:", error);
      setError("Unexpected error: " + error.message);
    }
  };

  const fetchUserFavorites = async () => {
    try {
      const { data, error } = await supabase
        .from("favorites")
        .select(
          `
          *,
          campsites (
            id,
            name
          )
        `
        )
        .eq("user_id", user.id);

      if (error) {
        console.error("Error fetching favorites:", error);
        setError("Error fetching your favorites: " + error.message);
        return;
      }

      setFavorites(data || []);
    } catch (error) {
      console.error("Error fetching favorites:", error);
      setError("Unexpected error: " + error.message);
    }
  };

  const fetchUserReviews = async () => {
    try {
      const { data, error } = await supabase
        .from("checkins")
        .select(
          `
          *,
          campsites (
            name,
            id
          ),
          bike_shops (
            name,
            id
          )
        `
        )
        .eq("user_id", user.id)
        .order("created_at", { ascending: false });

      console.log("reviews");
      console.log(data);

      if (error) {
        console.error("Error fetching reviews:", error);
        setError("Error fetching your reviews: " + error.message);
        return;
      }

      setReviews(data || []);
    } catch (error) {
      console.error("Error fetching reviews:", error);
      setError("Unexpected error: " + error.message);
    }
  };

  const fetchUserCampsites = async () => {
    try {
      const { data, error } = await supabase
        .from("campsites")
        .select("*")
        .eq("submitted_by", user.id);

      if (error) {
        console.error("Error fetching user campsites:", error);
        setError("Error fetching your campsites: " + error.message);
        return;
      }

      setCampsites(data || []);
    } catch (error) {
      console.error("Error fetching campsites:", error);
      setError("Unexpected error: " + error.message);
    }
  };

  const getVisibleCampsites = () => {
    return campsitesExpanded ? campsites : campsites.slice(0, ITEMS_TO_SHOW);
  };

  const getVisibleFavorites = () => {
    return favoritesExpanded ? favorites : favorites.slice(0, ITEMS_TO_SHOW);
  };

  const getVisibleCollections = () => {
    return collectionsExpanded
      ? collections
      : collections.slice(0, ITEMS_TO_SHOW);
  };

  const generateReviewLink = (review) => {
    let link = "";
    let name = "";
    if (review.campsite_id) {
      link = `/campsite/${review.campsites.id}`;
      name = review.campsites.name;
    } else {
      link = `/bikeshop/${review.bike_shops.id}`;
      name = review.bike_shops.name;
    }
    return (
      <Link to={link} className="text-blue-600 hover:text-blue-800 font-medium">
        {name}
      </Link>
    );
  };

  const getVisibleReviews = () => {
    return reviewsExpanded ? reviews : reviews.slice(0, ITEMS_TO_SHOW);
  };

  if (!user)
    return <div className="p-8">Please log in to view your profile.</div>;

  // Get full name if available
  const firstName = user.user_metadata?.first_name || "";
  const lastName = user.user_metadata?.last_name || "";
  const fullName = [firstName, lastName].filter(Boolean).join(" ");

  return (
    <div className="max-w-4xl mx-auto p-8">
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4">
          {error}
        </div>
      )}

      <div className="bg-white rounded-lg shadow-md p-6 mb-8">
        <h1 className="text-3xl font-bold mb-6">Profile</h1>

        <div className="space-y-4">
          {fullName && (
            <div>
              <h2 className="text-lg font-semibold">Name</h2>
              {loading ? (
                <LoadingPlaceholder />
              ) : (
                <p className="text-gray-600">{fullName}</p>
              )}
            </div>
          )}

          <div>
            <h2 className="text-lg font-semibold">Username</h2>
            {loading ? (
              <LoadingPlaceholder />
            ) : (
              <p className="text-gray-600">{username}</p>
            )}
          </div>

          <div>
            <h2 className="text-lg font-semibold">Email</h2>
            {loading ? (
              <LoadingPlaceholder />
            ) : (
              <p className="text-gray-600">{user.email}</p>
            )}
          </div>

          <div>
            <h2 className="text-lg font-semibold">Member For:</h2>
            {loading ? (
              <LoadingPlaceholder />
            ) : (
              <p className="text-gray-600">
                {Math.ceil(
                  Math.abs(new Date() - new Date(user.created_at)) /
                    (1000 * 60 * 60 * 24)
                )}{" "}
                days
              </p>
            )}
          </div>

          <div>
            <h2 className="text-lg font-semibold">Account Number</h2>
            {loading ? (
              <LoadingPlaceholder />
            ) : (
              <p className="text-gray-600">#{signUpOrder}</p>
            )}
          </div>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow-md p-6 mb-8">
        <div className="flex items-center gap-3 mb-4">
          <h2 className="text-2xl font-bold">Your Collections</h2>
          <span className="inline-flex items-center justify-center bg-blue-100 text-blue-800 text-sm font-medium h-6 w-6 rounded-full">
            {loading ? "..." : collections.length}
          </span>
        </div>

        {loading ? (
          <div className="space-y-4">
            {[...Array(3)].map((_, i) => (
              <div key={i} className="border-b pb-2">
                <LoadingPlaceholder />
              </div>
            ))}
          </div>
        ) : collections.length === 0 ? (
          <p className="text-gray-600">
            You haven't created any collections yet.
          </p>
        ) : (
          <div>
            <div className="space-y-4">
              {getVisibleCollections().map((collection) => (
                <div key={collection.id} className="border-b pb-4">
                  <div className="flex justify-between items-center">
                    <Link
                      to={`/manage/collection/${collection.id}`}
                      className="text-blue-600 hover:text-blue-800 font-medium"
                    >
                      {collection.name}
                    </Link>
                    <span className="text-gray-500">
                      {collection.collection_items.length}{" "}
                      {collection.collection_items.length > 1
                        ? "campsites"
                        : "campsite"}
                    </span>
                  </div>
                </div>
              ))}
            </div>
            {collections.length > ITEMS_TO_SHOW && (
              <button
                onClick={() => setCollectionsExpanded(!collectionsExpanded)}
                className="mt-4 text-blue-600 hover:text-blue-800 font-medium"
              >
                {collectionsExpanded
                  ? "Show Less"
                  : `Show All (${collections.length})`}
              </button>
            )}
          </div>
        )}
      </div>

      <div className="bg-white rounded-lg shadow-md p-6 mb-8">
        <div className="flex items-center gap-3 mb-4">
          <h2 className="text-2xl font-bold">Your Favorite Campsites</h2>
          <span className="inline-flex items-center justify-center bg-blue-100 text-blue-800 text-sm font-medium h-6 w-6 rounded-full">
            {loading ? "..." : favorites.length}
          </span>
        </div>

        {loading ? (
          <div className="space-y-4">
            {[...Array(3)].map((_, i) => (
              <div key={i} className="border-b pb-2">
                <LoadingPlaceholder />
              </div>
            ))}
          </div>
        ) : favorites.length === 0 ? (
          <p className="text-gray-600">
            You haven't favorited any campsites yet.
          </p>
        ) : (
          <div>
            <div className="space-y-2">
              {getVisibleFavorites().map((favorite) => (
                <div key={favorite.id} className="border-b pb-2">
                  <Link
                    to={`/campsite/${favorite.campsites.id}`}
                    className="text-blue-600 hover:text-blue-800 font-medium"
                  >
                    {favorite.campsites.name}
                  </Link>
                </div>
              ))}
            </div>
            {favorites.length > ITEMS_TO_SHOW && (
              <button
                onClick={() => setFavoritesExpanded(!favoritesExpanded)}
                className="mt-4 text-blue-600 hover:text-blue-800 font-medium"
              >
                {favoritesExpanded
                  ? "Show Less"
                  : `Show All (${favorites.length})`}
              </button>
            )}
          </div>
        )}
      </div>

      <div className="bg-white rounded-lg shadow-md p-6 mb-8">
        <div className="flex items-center gap-3 mb-4">
          <h2 className="text-2xl font-bold">Your Contributed Campsites</h2>
          <span className="inline-flex items-center justify-center bg-blue-100 text-blue-800 text-sm font-medium h-6 w-6 rounded-full">
            {loading ? "..." : campsites.length}
          </span>
        </div>

        {loading ? (
          <div className="space-y-4">
            {[...Array(3)].map((_, i) => (
              <div key={i} className="border-b pb-2">
                <LoadingPlaceholder />
              </div>
            ))}
          </div>
        ) : campsites.length === 0 ? (
          <p className="text-gray-600">
            You haven't contributed any campsites yet.
          </p>
        ) : (
          <div>
            <div className="space-y-2">
              {getVisibleCampsites().map((campsite) => (
                <div key={campsite.id} className="border-b pb-2">
                  <Link
                    to={`/campsite/${campsite.id}`}
                    className="text-blue-600 hover:text-blue-800 font-medium"
                  >
                    {campsite.name}
                  </Link>
                </div>
              ))}
            </div>
            {campsites.length > ITEMS_TO_SHOW && (
              <button
                onClick={() => setCampsitesExpanded(!campsitesExpanded)}
                className="mt-4 text-blue-600 hover:text-blue-800 font-medium"
              >
                {campsitesExpanded
                  ? "Show Less"
                  : `Show All (${campsites.length})`}
              </button>
            )}
          </div>
        )}
      </div>

      <div className="bg-white rounded-lg shadow-md p-6">
        <div className="flex items-center gap-3 mb-4">
          <h2 className="text-2xl font-bold">Your Reviews</h2>
          <span className="inline-flex items-center justify-center bg-blue-100 text-blue-800 text-sm font-medium h-6 w-6 rounded-full">
            {loading ? "..." : reviews.length}
          </span>
        </div>

        {loading ? (
          <div className="space-y-4">
            {[...Array(3)].map((_, i) => (
              <div key={i} className="border-b pb-4">
                <div className="flex justify-between items-center mb-2">
                  <div className="w-1/3">
                    <LoadingPlaceholder />
                  </div>
                  <div className="w-24">
                    <LoadingPlaceholder />
                  </div>
                </div>
                <div className="w-full">
                  <LoadingPlaceholder />
                </div>
              </div>
            ))}
          </div>
        ) : reviews.length === 0 ? (
          <p className="text-gray-600">You haven't reviewed anything yet.</p>
        ) : (
          <div>
            <div className="space-y-4">
              {getVisibleReviews().map((review) => (
                <div key={review.id} className="border-b">
                  <div className="flex justify-between items-center mb-2">
                    {generateReviewLink(review)}
                    <span className="text-sm text-gray-500">
                      {new Date(review.created_at).toLocaleDateString()}
                    </span>
                  </div>
                  <p className="text-gray-600">
                    {review.review?.length > 100
                      ? `${review.review.substring(0, 100)}...`
                      : review.review}
                  </p>
                </div>
              ))}
            </div>
            {reviews.length > ITEMS_TO_SHOW && (
              <button
                onClick={() => setReviewsExpanded(!reviewsExpanded)}
                className="mt-4 text-blue-600 hover:text-blue-800 font-medium"
              >
                {reviewsExpanded ? "Show Less" : `Show All (${reviews.length})`}
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfileView;

import React, { useEffect, useState, useRef, useMemo } from "react";
import Map, {
  Source,
  Layer,
  Popup,
  NavigationControl,
  GeolocateControl,
} from "react-map-gl";
import { isMobile } from "react-device-detect";
import "mapbox-gl/dist/mapbox-gl.css";
import FilterControl from "./FilterControl";
import CampsitePopup from "./CampsitePopup";
import BikeShopPopup from "./BikeShopPopup";
import CampsiteInfoDrawer from "./CampsiteInfoDrawer";
import BikeShopInfoDrawer from "./BikeShopInfoDrawer";
import CollectionsModal from "../CollectionsModal";
import { useAuth } from "../../context/AuthProvider";
import "../../styles/App.css";

const MapboxComponent = ({ allMapData }) => {
  const mapContainerRef = useRef(null);
  const map = useRef(null);
  const mapRef = useRef(null);
  const { user } = useAuth();

  const [lng] = useState(-98.1739304110168);
  const [lat] = useState(39.55519650847471);
  const [zoom] = useState(4);
  const [showPopup, setShowPopup] = useState(false);
  const [showNamePopup, setShowNamePopup] = useState(false);
  const [popupCoordinates, setPopupCoordinates] = useState([-100, 40]);
  const [selectedProperties, setSelectedProperties] = useState({
    description: "",
  });
  const [selectedFeatureType, setSelectedFeatureType] = useState(null);
  const [freeFilterChecked, setFreeFilter] = useState(false);
  const [selectedTypes, setSelectedTypes] = useState([
    "campground",
    "h/b campground",
    "park",
    "church",
    "rv park",
    "no-turn-away",
    "dispersed",
    "business",
    "hostel",
    "hotel",
    "bike_shop",
  ]);
  const [showBikeShops, setShowBikeShops] = useState(true);
  const [campsiteInfoOpen, setCampsiteInfoOpen] = useState(false);
  const [bikeShopInfoOpen, setBikeShopInfoOpen] = useState(false);
  const [cursorStyle, setCursorStyle] = useState();
  const [mobileHeight, setMobileHeight] = useState(800);
  const [showCollectionsModal, setShowCollectionsModal] = useState(false);

  useEffect(() => {
    if (isMobile) {
      setMobileHeight(700);
    }
  }, []);

  // Filter the GeoJSON data based on current filters
  const filteredData = useMemo(() => {
    if (!allMapData || !allMapData.features)
      return { type: "FeatureCollection", features: [] };

    const filteredFeatures = allMapData.features.filter((feature) => {
      const isBikeShop = feature.properties.type_of_facility === "bike_shop";
      if (isBikeShop) {
        return showBikeShops;
      }

      const matchesType = selectedTypes.includes(
        feature.properties.type_of_facility
      );
      const matchesCost =
        !freeFilterChecked ||
        feature.properties.cost === 0 ||
        feature.properties.cost === null;
      return matchesType && matchesCost;
    });

    return {
      type: "FeatureCollection",
      features: filteredFeatures,
    };
  }, [allMapData, selectedTypes, freeFilterChecked, showBikeShops]);

  function updateFilters() {
    setFreeFilter(!freeFilterChecked);
    setShowPopup(false);
  }

  function updateTypeFilters(type) {
    setSelectedTypes((prev) => {
      if (prev.includes(type)) {
        return prev.filter((t) => t !== type);
      } else {
        return [...prev, type];
      }
    });
    setShowPopup(false);
  }

  function toggleBikeShops() {
    setShowBikeShops(!showBikeShops);
    setShowPopup(false);
  }

  const onMouseEnter = (e) => {
    setCursorStyle("pointer");
    if (isMobile || showPopup) return;

    const features = mapRef.current.queryRenderedFeatures(e.point, {
      layers: ["unclustered-point-campsites", "unclustered-point-bikeshops"],
    });

    if (features.length > 0) {
      const feature = features[0];
      let coordinates = feature.geometry.coordinates.slice();
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      setSelectedProperties(feature.properties);
      setPopupCoordinates(coordinates);
      setShowNamePopup(true);
    }
  };

  const onMouseLeave = (e) => {
    setShowNamePopup(false);
    setCursorStyle("");
  };

  const clusterLayer = {
    id: "clusters",
    type: "circle",
    source: "allData",
    filter: ["has", "point_count"],
    paint: {
      "circle-color": [
        "step",
        ["get", "point_count"],
        "#2ecc71",
        10,
        "#f39c12",
        100,
        "#f1c40f",
        750,
        "#51bbd6",
      ],
      "circle-radius": ["step", ["get", "point_count"], 20, 100, 30, 750, 40],
      "circle-opacity": 1,
      "circle-stroke-width": 5,
      "circle-stroke-color": [
        "step",
        ["get", "point_count"],
        "#2ecc71",
        10,
        "#f39c12",
        100,
        "#f1c40f",
        750,
        "#51bbd6",
      ],
      "circle-stroke-opacity": 0.3,
    },
  };

  const clusterCountLayer = {
    id: "cluster-count",
    type: "symbol",
    source: "allData",
    filter: ["has", "point_count"],
    layout: {
      "text-field": "{point_count_abbreviated}",
      "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
      "text-size": 12,
    },
  };

  const unclusteredCampsiteLayer = {
    id: "unclustered-point-campsites",
    type: "circle",
    source: "allData",
    filter: [
      "all",
      ["!", ["has", "point_count"]],
      ["!=", ["get", "type_of_facility"], "bike_shop"],
    ],
    paint: {
      "circle-color": [
        "match",
        ["get", "type_of_facility"],
        "campground",
        "#fbb03b",
        "h/b campground",
        "#2ecc71",
        "park",
        "#00FFFF",
        "church",
        "#fff",
        "rv park",
        "#b2bec3",
        "no-turn-away",
        "#e056fd",
        "dispersed",
        "#e056fd",
        "business",
        "#ffff00",
        "hostel",
        "#ffff00",
        "hotel",
        "#ffff00",
        "#4264fb",
      ],
      "circle-radius": 6,
      "circle-stroke-width": 2,
      "circle-stroke-color": "#ffffff",
    },
  };

  const unclusteredBikeShopLayer = {
    id: "unclustered-point-bikeshops",
    type: "symbol",
    source: "allData",
    filter: [
      "all",
      ["!", ["has", "point_count"]],
      ["==", ["get", "type_of_facility"], "bike_shop"],
    ],
    layout: {
      "icon-image": "bicycle-15",
      "icon-size": 1.5,
      "icon-allow-overlap": true,
      // "text-field": ["get", "name"],
      "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
      "text-offset": [0, 0.6],
      "text-anchor": "top",
      "text-size": 12,
    },
    paint: {
      "text-color": "#333",
      "text-halo-color": "#fff",
      "text-halo-width": 1,
    },
  };

  const onClick = (e) => {
    if (e.features.length === 0) return;

    const feature = e.features[0];
    if (feature.layer.id === "clusters") {
      const clusterId = feature.properties.cluster_id;
      const mapboxSource = mapRef.current.getSource("allData");

      mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
        if (err) return;

        mapRef.current.easeTo({
          center: feature.geometry.coordinates,
          zoom,
          duration: 500,
        });
      });
    } else if (
      feature.layer.id === "unclustered-point-campsites" ||
      feature.layer.id === "unclustered-point-bikeshops"
    ) {
      let coordinates = feature.geometry.coordinates.slice();
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      mapRef.current.easeTo({
        center: feature.geometry.coordinates,
        duration: 500,
      });

      setSelectedProperties(feature.properties);
      setPopupCoordinates(coordinates);
      setSelectedFeatureType(
        feature.properties.type_of_facility === "bike_shop"
          ? "bikeshop"
          : "campsite"
      );
      setShowPopup(true);
    }
  };

  return (
    <>
      <div className="relative">
        <CampsiteInfoDrawer
          campsiteInfoOpen={campsiteInfoOpen}
          setCampsiteInfoOpen={setCampsiteInfoOpen}
          properties={selectedProperties}
          coordinates={popupCoordinates}
          onAddToCollections={() => setShowCollectionsModal(true)}
        />

        <BikeShopInfoDrawer
          bikeShopInfoOpen={bikeShopInfoOpen}
          setBikeShopInfoOpen={setBikeShopInfoOpen}
          properties={selectedProperties}
          coordinates={popupCoordinates}
        />

        <Map
          mapboxAccessToken={process.env.REACT_APP_MAPBOX}
          initialViewState={{
            longitude: lng,
            latitude: lat,
            zoom: zoom,
          }}
          reuseMaps
          style={{ height: mobileHeight }}
          cursor={cursorStyle}
          width="100%"
          mapStyle="mapbox://styles/mapbox/streets-v11"
          interactiveLayerIds={[
            clusterLayer.id,
            unclusteredCampsiteLayer.id,
            unclusteredBikeShopLayer.id,
          ]}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onClick={onClick}
          ref={mapRef}
        >
          <NavigationControl style={{ marginRight: "25px" }} />
          <GeolocateControl
            positionOptions={{ enableHighAccuracy: true }}
            trackUserLocation={true}
            showUserLocation={true}
            auto={true}
            style={{ marginRight: "25px" }}
          />
          <Source
            id="allData"
            type="geojson"
            data={filteredData}
            cluster={true}
            clusterMaxZoom={14}
            clusterRadius={30}
          >
            <Layer {...clusterLayer} />
            <Layer {...clusterCountLayer} />
            <Layer {...unclusteredCampsiteLayer} />
            <Layer {...unclusteredBikeShopLayer} />
          </Source>

          <FilterControl
            freeFilterChecked={freeFilterChecked}
            updateFiltersFunc={updateFilters}
            selectedTypes={selectedTypes}
            updateTypeFilters={updateTypeFilters}
            showBikeShops={showBikeShops}
            toggleBikeShops={toggleBikeShops}
          />

          {showPopup && (
            <Popup
              key={"info" + popupCoordinates[0] + popupCoordinates[1]}
              longitude={popupCoordinates[0]}
              latitude={popupCoordinates[1]}
              onClose={() => setShowPopup(false)}
              maxWidth="300px"
            >
              {selectedFeatureType === "bikeshop" ? (
                <BikeShopPopup
                  properties={selectedProperties}
                  coordinates={popupCoordinates}
                  setBikeShopInfoOpen={setBikeShopInfoOpen}
                />
              ) : (
                <CampsitePopup
                  properties={selectedProperties}
                  coordinates={popupCoordinates}
                  setCampsiteInfoOpen={setCampsiteInfoOpen}
                />
              )}
            </Popup>
          )}

          {showNamePopup && (
            <Popup
              key={popupCoordinates[0] + popupCoordinates[1]}
              longitude={popupCoordinates[0]}
              latitude={popupCoordinates[1]}
              closeButton={false}
              maxWidth="240"
              style={{ textAlign: "center" }}
            >
              <h2 className="text-sm">{selectedProperties.name}</h2>
            </Popup>
          )}
        </Map>
      </div>

      <div className="fixed inset-0 z-[99999999] pointer-events-none">
        <div className="pointer-events-auto">
          <CollectionsModal
            isOpen={showCollectionsModal}
            onClose={() => setShowCollectionsModal(false)}
            campsiteId={selectedProperties.id}
            userId={user?.id}
          />
        </div>
      </div>
    </>
  );
};

export default MapboxComponent;

import MapboxComponent from "../Components/MapComponents/MapboxComponent.js";

export function MapView({ allCampsites, bikeShopData }) {
  // Combine campsite and bike shop data into a single GeoJSON
  const combinedData = {
    type: "FeatureCollection",
    features: [
      ...(allCampsites?.features || []),
      ...(bikeShopData?.features?.map((feature) => ({
        ...feature,
        properties: {
          ...feature.properties,
          type_of_facility: "bike_shop", // Add type for consistent filtering
        },
      })) || []),
    ],
  };

  return (
    <div>
      <MapboxComponent allMapData={combinedData} />
    </div>
  );
}

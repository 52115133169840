import React, { useState, useEffect } from "react";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import "../../styles/FilterControlStyles.css";
import { Link } from "react-router-dom";

export default function CampsitePopup({
  properties,
  coordinates,
  setCampsiteInfoOpen,
}) {
  // const hasWater = properties.water === true;
  // const hasTables = properties.tables === true;
  // const hasShowers = properties.showers === true;
  // const hasFoodStorage = properties.food_storage === true;
  // const hasElectricity = properties.electricity === true;
  let hasAdvisory = false;

  if (properties.advisory) {
    hasAdvisory = true;
  }

  const toiletType = properties.toilet;
  let hasToilet = false;

  if (toiletType && toiletType !== false) {
    hasToilet = true;
  }

  const siteCost = properties.cost;
  const siteCostUnit = properties.cost_unit;
  let CostTag = "";

  if (properties.donation_based === true) {
    CostTag = "<p><b>Cost: </b>Donation</p>";
  } else if (isNaN(siteCost)) {
    CostTag = "<p><b>Cost: </b>unsure</p>";
  } else if (siteCost > 0) {
    CostTag = `<p><b>Cost: </b>$${siteCost}/${siteCostUnit}</p>`;
  } else {
    CostTag = "<p><b>Cost: </b>free</p>";
  }
  return (
    <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
      <div
        className="font-bold text-base px-2 py-3 sm:p-3"
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {properties.name}
      </div>
      <div className="p-3 display-linebreak">
        {hasAdvisory ? (
          <div className="rounded-md bg-yellow-50 p-2">
            <div className="flex">
              <div className="flex-shrink-0">
                <ExclamationTriangleIcon
                  className="h-5 w-5 text-yellow-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-yellow-800">
                  Attention needed
                </h3>
                <div className="mt-2 text-sm text-yellow-700">
                  <p>See more info for details</p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {properties.description ? (
          <p style={{ marginTop: "10px" }}>
            {properties.description.substring(0, 100) + "..."}
          </p>
        ) : (
          ""
        )}
        <a
          className="cursor-pointer underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
          target="_blank"
          href={
            "https://www.google.com/maps/place/" +
            coordinates[1] +
            "," +
            coordinates[0]
          }
        >
          Map
        </a>

        {properties.phone_number ? (
          <span>
            <br />

            <a
              className="cursor-pointer underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
              href={"tel:" + properties.phone_number}
            >
              {properties.phone_number.slice(0, 3) +
                "-" +
                properties.phone_number.slice(3, 6) +
                "-" +
                properties.phone_number.slice(6)}
            </a>
          </span>
        ) : (
          ""
        )}

        {properties.website_url ? (
          <span>
            <br />

            <a
              className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
              target="_blank"
              href={properties.website_url}
            >
              Website
            </a>
          </span>
        ) : (
          ""
        )}
        <br />
        <span dangerouslySetInnerHTML={{ __html: CostTag }}></span>
      </div>
      <div
        className="flex items-center justify-center bg-white p-1"
        style={{ borderTop: "none" }}
      >
        <div className="flex flex-wrap justify-center gap-x-6 gap-y-4">
          <a
            className="cursor-pointer underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
            onClick={() => setCampsiteInfoOpen(true)}
          >
            more info &gt;&gt;
          </a>
          <Link
            key={properties.id}
            target={"_blank"}
            to={`/campsite/${properties.id}`}
            className="cursor-pointer underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
          >
            campsite page &gt;&gt;
          </Link>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import "../../styles/FilterControlStyles.css";

export default function MapKey() {
  return (
    <div className="mapKey">
      <div className="mapKeyItem">
        <span className="dot campgroundWithout-dot"></span>
        <p>Campground</p>
      </div>
      <div className="mapKeyItem">
        <span className="dot campgroundWith-dot"></span>
        <p>Campground w/ h/b</p>
      </div>
      <div className="mapKeyItem">
        <span className="dot park-dot"></span>
        <p>Park</p>
      </div>
      <div className="mapKeyItem">
        <span className="dot church-dot"></span>
        <p>Church</p>
      </div>
      <div className="mapKeyItem">
        <span className="dot rvPark-dot"></span>
        <p>RV Park</p>
      </div>
      <div className="mapKeyItem">
        <span className="dot business-dot"></span>
        <p>Business/Hostel</p>
      </div>
      <div className="mapKeyItem">
        <span className="dot dispersed-dot"></span>
        <p>Dispersed</p>
      </div>
      <div className="mapKeyItem">
        <span className="dot misc-dot"></span>
        <p>Miscellaneous</p>
      </div>
      <div className="mapKeyItem">
        <span className="bike-shop-icon">🚲</span>
        <p>Bike Shop</p>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useAuth } from "../context/AuthProvider";
import { supabase } from "../supabase/client";
import { LinkIcon } from "@heroicons/react/24/outline";

const LoadingPlaceholder = () => (
  <div className="animate-pulse">
    <div className="h-8 bg-gray-200 rounded w-1/3 mb-4"></div>
    <div className="h-4 bg-gray-200 rounded w-1/4 mb-6"></div>
    <div className="space-y-4">
      <div className="h-4 bg-gray-200 rounded w-full"></div>
      <div className="h-4 bg-gray-200 rounded w-full"></div>
      <div className="h-4 bg-gray-200 rounded w-3/4"></div>
    </div>
  </div>
);

const CollectionView = () => {
  const { id } = useParams();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [collection, setCollection] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedName, setEditedName] = useState("");
  const [editedDescription, setEditedDescription] = useState("");
  const [isPublic, setIsPublic] = useState(false);
  const [showCopied, setShowCopied] = useState(false);

  useEffect(() => {
    fetchCollection();
  }, [id]);

  useEffect(() => {
    if (showCopied) {
      const timer = setTimeout(() => setShowCopied(false), 2000);
      return () => clearTimeout(timer);
    }
  }, [showCopied]);

  const copyPublicLink = async () => {
    const url = `${window.location.origin}/collection/${id}`;
    try {
      await navigator.clipboard.writeText(url);
      setShowCopied(true);
    } catch (err) {
      console.error("Failed to copy:", err);
    }
  };

  const fetchCollection = async () => {
    try {
      const { data, error } = await supabase
        .from("collections")
        .select(
          `
          *,
          collection_items (
            campsite_id,
            campsites (
              id,
              name
            )
          )
        `
        )
        .eq("id", id)
        .single();

      if (error) throw error;

      if (data.user_id !== user.id) {
        navigate("/profile");
        return;
      }

      setCollection(data);
      setEditedName(data.name);
      setEditedDescription(data.description || "");
      setIsPublic(data.is_public || false);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching collection:", error);
      setError(error.message);
      setLoading(false);
    }
  };

  const handleSave = async () => {
    try {
      const { error } = await supabase
        .from("collections")
        .update({
          name: editedName,
          description: editedDescription,
          is_public: isPublic,
        })
        .eq("id", id);

      if (error) throw error;

      setCollection({
        ...collection,
        name: editedName,
        description: editedDescription,
        is_public: isPublic,
      });
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating collection:", error);
      setError(error.message);
    }
  };

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this collection?")) {
      try {
        const { error } = await supabase
          .from("collections")
          .delete()
          .eq("id", id);

        if (error) throw error;

        navigate("/profile");
      } catch (error) {
        console.error("Error deleting collection:", error);
        setError(error.message);
      }
    }
  };

  const handleRemoveCampsite = async (campsiteId) => {
    if (window.confirm("Remove this campsite from the collection?")) {
      try {
        const { error } = await supabase
          .from("collection_items")
          .delete()
          .eq("collection_id", id)
          .eq("campsite_id", campsiteId);

        if (error) throw error;

        setCollection({
          ...collection,
          collection_items: collection.collection_items.filter(
            (item) => item.campsite_id !== campsiteId
          ),
        });
      } catch (error) {
        console.error("Error removing campsite:", error);
        setError(error.message);
      }
    }
  };

  if (error) return <div className="p-8 text-red-500">Error: {error}</div>;

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-grow max-w-screen-2xl mx-auto p-4 sm:p-8">
        <div className="bg-white rounded-lg shadow-md p-4 sm:p-6">
          {loading ? (
            <LoadingPlaceholder />
          ) : isEditing ? (
            <div className="w-full sm:min-w-[500px]">
              <div className="mb-6">
                <h1 className="text-2xl sm:text-3xl font-bold mb-6">
                  Edit Collection
                </h1>
                <div className="space-y-6">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Collection Name
                    </label>
                    <input
                      type="text"
                      value={editedName}
                      onChange={(e) => setEditedName(e.target.value)}
                      className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-base sm:text-lg py-2"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Description
                    </label>
                    <textarea
                      value={editedDescription}
                      onChange={(e) => setEditedDescription(e.target.value)}
                      placeholder="Write a description of this collection..."
                      className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 placeholder:italic placeholder:text-gray-400 text-base sm:text-lg"
                      rows="4"
                    />
                  </div>

                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      checked={isPublic}
                      onChange={(e) => setIsPublic(e.target.checked)}
                      className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    />
                    <label className="ml-2 block text-sm text-gray-900">
                      Make this collection public
                    </label>
                  </div>

                  <div className="flex flex-wrap gap-2">
                    <button
                      onClick={handleSave}
                      className="bg-blue-600 text-white px-6 py-2 rounded hover:bg-blue-700 text-base sm:text-lg"
                    >
                      Save Changes
                    </button>
                    <button
                      onClick={() => setIsEditing(false)}
                      className="bg-gray-200 text-gray-800 px-6 py-2 rounded hover:bg-gray-300 text-base sm:text-lg"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4 mb-6">
                <h1 className="text-2xl sm:text-3xl font-bold">
                  {collection.name}
                </h1>
                <div className="flex flex-wrap gap-2">
                  <button
                    onClick={() => setIsEditing(true)}
                    className="bg-blue-600 text-white px-3 py-1.5 sm:px-4 sm:py-2 text-sm sm:text-base rounded hover:bg-blue-700"
                  >
                    Edit
                  </button>
                  <button
                    onClick={handleDelete}
                    className="bg-red-600 text-white px-3 py-1.5 sm:px-4 sm:py-2 text-sm sm:text-base rounded hover:bg-red-700"
                  >
                    Delete Collection
                  </button>
                </div>
              </div>

              {collection.description ? (
                <p className="text-gray-600 mb-6">{collection.description}</p>
              ) : (
                <p className="text-gray-400 italic mb-6">No description</p>
              )}

              <div className="mb-4 flex items-center gap-4">
                <span className="text-sm font-medium text-gray-500">
                  {isPublic ? "Public Collection" : "Private Collection"}
                </span>
                {isPublic && (
                  <div className="relative">
                    <button
                      onClick={copyPublicLink}
                      className="flex items-center gap-1 text-sm text-blue-600 hover:text-blue-800"
                    >
                      <LinkIcon className="h-4 w-4" />
                      Copy Link
                    </button>
                    {showCopied && (
                      <span className="absolute left-0 -bottom-6 text-xs text-green-600">
                        Copied!
                      </span>
                    )}
                  </div>
                )}
              </div>

              <h2 className="text-xl font-semibold mb-4">
                Campsites ({collection.collection_items.length})
              </h2>

              <div className="overflow-x-auto -mx-4 sm:mx-0">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-4 sm:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Campsite Name
                      </th>
                      <th
                        scope="col"
                        className="px-4 sm:px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {collection.collection_items.map((item) => (
                      <tr key={item.campsite_id} className="hover:bg-gray-50">
                        <td className="px-4 sm:px-6 py-4 whitespace-normal">
                          <Link
                            to={`/campsite/${item.campsites.id}`}
                            className="text-blue-600 hover:text-blue-800"
                          >
                            {item.campsites.name}
                          </Link>
                        </td>
                        <td className="px-4 sm:px-6 py-4 text-right">
                          <button
                            onClick={() =>
                              handleRemoveCampsite(item.campsite_id)
                            }
                            className="text-red-600 hover:text-red-800 text-sm"
                          >
                            Remove
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CollectionView;
